<template>
  <form class="flex flex-col space-y-6" @submit="onSubmit">
    <h2 v-if="!editMode" class="h5 font-bold block">What's your function about?</h2>
    <UserAvatarEditable
      v-model:edit-mode="avatarEditMode"
      name="image"
      placeholder="F N"
      @update:model-value="avatarEditMode = false"
    />
    <FormTextInput
      size="lg"
      name="name"
      label="Name"
      placeholder="My Awesome Function!"
      help="This will be used as the function's display name and also as the name of the Git repository."
      show-label
      show-required
      :rules="nameRules"
    />
    <FormMarkdownEditor
      name="description"
      label="Description"
      show-label
      show-required
      :rules="descriptionRules"
    />
    <FormSelectSourceApps
      name="allowedSourceApps"
      label="Supported source apps"
      show-label
      multiple
      help="Versions submitted from these apps will support this function. If left empty, all apps will be supported."
      clearable
      button-style="tinted"
    />
    <FormTags
      name="tags"
      label="Tags"
      show-label
      show-clear
      help="Appropriate tags will help other people find your function."
    />
    <FormSelectBase
      v-if="availableOrgs.length && !editMode"
      name="org"
      label="Organization"
      show-label
      allow-unset
      button-style="tinted"
      clearable
      placeholder="Choose a GitHub organization (optional)"
      help="Choose an organization to publish your Git repository to. If left empty, it will be published to your personal account."
      :items="availableOrgs"
    >
      <template #something-selected="{ value }">
        <div class="label label--light">
          {{ isArray(value) ? value[0] : value }}
        </div>
      </template>
      <template #option="{ item, selected }">
        <div class="flex flex-col">
          <div :class="['label label--light', selected ? 'text-primary' : '']">
            {{ item }}
          </div>
        </div>
      </template>
    </FormSelectBase>
    <div v-if="!editMode" class="flex justify-between">
      <FormButton outlined :disabled="loading" @click="$emit('back')">Back</FormButton>
      <FormButton submit :disabled="loading">Next</FormButton>
    </div>
  </form>
</template>
<script setup lang="ts">
import { ValidationHelpers } from '@speckle/ui-components'
import { watchTriggerable } from '@vueuse/core'
import { isArray } from 'lodash-es'
import { useForm } from 'vee-validate'
import type { DetailsFormValues } from '~/lib/frontend/functions/helpers/create'
import { useFunctionsStore } from '~/lib/frontend/functions/stores/functions'

const emit = defineEmits<{
  back: []
  next: [DetailsFormValues]
}>()

defineProps<{
  loading?: boolean
  editMode?: boolean
}>()

const model = defineModel<DetailsFormValues>({ local: true })
const { handleSubmit, resetForm } = useForm<DetailsFormValues>()
const functions = useFunctionsStore()
const avatarEditMode = ref(false)

const nameRules = computed(() => [
  ValidationHelpers.isRequired,
  ValidationHelpers.isStringOfLength({ maxLength: 150 })
])
const descriptionRules = computed(() => [ValidationHelpers.isRequired])
const availableOrgs = computed(() => functions.creationMetadata?.availableOrgs || [])

const onSubmit = handleSubmit((vals) => {
  emit('next', vals)
})

const triggerSubmit = async () => {
  await onSubmit()
}

const { trigger: triggerModelWatcher } = watchTriggerable(
  model,
  (newModel) => {
    if (!newModel) return
    resetForm({ values: newModel })
  },
  { deep: true }
)

onMounted(() => {
  // FIX for sourceApps not initializing properly. This issue is essentially caused by all of our components setting `initialValue = props.modelValue`, which ends up overrwriting the value unnecessary
  // TODO: We should make initialValue behavior in ui-components more explicit...
  triggerModelWatcher()
})

defineExpose({
  triggerSubmit
})
</script>
