<template>
  <div
    :class="`space-y-4 ${
      limitMaxHeight ? 'h-96 simple-scrollbar overflow-y-scroll p-2' : ''
    }`"
  >
    <CommonHeader v-if="!selectable">
      <template #header>Functions</template>
      <template #subheader>
        We have templates for Python, .NET, JS, TS - and Jupyter Notebooks. Start from
        our templates in no time!
      </template>
    </CommonHeader>
    <div
      class="flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:items-center sm:justify-between"
    >
      <div class="flex items-center space-x-2">
        <div>
          <h2 class="h4 font-bold">Functions</h2>
          <div class="text-foreground-2">
            Select a function to create an automation on your project from it!
          </div>
        </div>
      </div>

      <div class="flex items-center space-x-2">
        <FormButton
          v-if="core.isLoggedIn && !selectable"
          size="lg"
          color="card"
          :icon-right="PlusIcon"
          @click="newFunctionDialogOpen = true"
        >
          Add New
        </FormButton>
        <FormTextInput
          name="functionsSearch"
          placeholder="Search"
          color="foundation"
          size="lg"
          show-clear
          :wrapper-classes="
            ['w-full sm:w-auto simple-scrollbar', searchClasses || ''].join(' ')
          "
          @change="changeHandler"
          @update:model-value="updateModelValueHandler"
        />
      </div>
    </div>
    <div
      :class="`grid grid-cols-1 gap-4 sm:grid-cols-2 ${
        selectable ? '' : 'xl:grid-cols-3'
      } `"
    >
      <template v-for="fn in functions" :key="fn.functionId">
        <FunctionsCardMarketplace
          :fn="fn"
          :selected="isFunctionSelected(fn)"
          :selectable="selectable"
          :disabled="limitSelectionToActive && !fn.functionVersions.length"
          disabled-tooltip="Function has no active versions"
          @update:selected="($event) => selectFunction($event, fn)"
        />
      </template>
      <div :class="`col-span-1 sm:col-span-2 ${selectable ? '' : 'xl:col-span-3'}  `">
        <InfiniteLoading
          :settings="{ identifier: inputValue }"
          @infinite="infiniteLoad"
        />
      </div>
    </div>
    <FunctionsCreateDialog v-model:open="newFunctionDialogOpen" />
  </div>
</template>
<script setup lang="ts">
import { PlusIcon } from '@heroicons/vue/24/solid'
import type { InfiniteLoaderState } from '@speckle/ui-components'
import { uniqBy } from 'lodash-es'
import { useDebouncedInputValue } from '~/lib/frontend/common/composables/dom'
import { useLogger } from '~/lib/frontend/common/composables/logger'
import { useCoreStore } from '~/lib/frontend/core/stores/core'
import type { GetFunctionsItem } from '~/lib/frontend/functions/composables/management'
import { useGetFunctions } from '~/lib/frontend/functions/composables/management'
const core = useCoreStore()

const newFunctionDialogOpen = ref(false)

type FunctionType = GetFunctionsItem

const props = withDefaults(
  defineProps<{
    limit?: number
    searchClasses?: string
    selectable?: boolean
    limitSelectionToOne?: boolean
    limitSelectionToActive?: boolean
    limitMaxHeight?: boolean
    dialogWidth?: boolean
  }>(),
  {
    limit: 20
  }
)

const selectedFunctions = defineModel<FunctionType[]>('selectedFunctions')

const logger = useLogger()
const {
  changeHandler,
  updateModelValueHandler,
  value: inputValue
} = useDebouncedInputValue({
  onValueUpdated: () => {
    void functionsReq.reset((oldOpts) => ({
      query: {
        ...oldOpts.query,
        query: inputValue.value,
        cursor: undefined
      }
    }))
  }
})
const functionsReq = useGetFunctions({
  cursor: undefined,
  limit: props.limit,
  query: undefined
})

const functions = computed(() => functionsReq.data.value?.items || [])

const infiniteLoad = async (state: InfiniteLoaderState) => {
  try {
    const didLoad = await functionsReq.loadMore((oldOpts) => ({
      query: {
        ...oldOpts.query,
        cursor: functionsReq.data.value?.cursor || undefined
      }
    }))
    state.loaded()

    if (!didLoad) {
      state.complete()
    }
  } catch (e) {
    logger.error(e)
    state.error()
  }
}

const isFunctionSelected = (fn: FunctionType) => {
  return !!(selectedFunctions.value || []).find((f) => f.functionId === fn.functionId)
}

const selectFunction = (selected: boolean, fn: FunctionType) => {
  let newFunctions = (selectedFunctions.value || []).slice()

  if (selected) {
    newFunctions.push(fn)
  } else {
    const index = newFunctions.findIndex((f) => f.functionId === fn.functionId)
    if (index > -1) {
      newFunctions.splice(index, 1)
    }
  }

  if (selected && props.limitSelectionToOne && newFunctions.length > 1) {
    newFunctions = [fn]
  }

  newFunctions = uniqBy(newFunctions, 'functionId')
  selectedFunctions.value = newFunctions
}

void functionsReq.promise
</script>
