<template>
  <LayoutDialog
    v-model:open="finalOpen"
    :max-width="maxWidth"
    prevent-close-on-click-outside
    @fully-closed="onFullyClosed"
  >
    <div class="flex flex-col space-y-4">
      <FunctionsCreateDialogAuthorizeGithubStep v-if="!hasGithubAuth" />
      <FunctionsCreateDialogTemplateStep
        v-else-if="activeStep === Steps.Template"
        v-model:selected-template="selectedTemplate"
        @next="activeStep = Steps.Details"
      />
      <FunctionsCreateDialogDetailsStep
        v-else-if="activeStep === Steps.Details"
        v-model="details"
        :template-id="selectedTemplate?.language"
        :loading="isDetailsLoading"
        @back="activeStep = Steps.Template"
        @next="onDetailsNext"
      />
      <FunctionsCreateDialogDoneStep
        v-else-if="activeStep === Steps.Done"
        :new-function="newFunction"
      />
      <CommonStepsBullet
        v-if="hasGithubAuth"
        v-model="activeStep"
        :steps="steps"
        non-interactive
        basic
        steps-padding="sm"
      />
    </div>
  </LayoutDialog>
</template>
<script setup lang="ts">
import type { BulletStepType } from '@speckle/ui-components'
import { useCoreStore } from '~/lib/frontend/core/stores/core'
import type {
  CreateFunctionResponse,
  FunctionTemplateItem
} from '~/lib/frontend/functions/composables/management'
import type { DetailsFormValues } from '~/lib/frontend/functions/helpers/create'
import { useFunctionsStore } from '~/lib/frontend/functions/stores/functions'

const emit = defineEmits<(e: 'update:open', val: boolean) => void>()

const props = defineProps<{
  open: boolean
}>()

const functions = useFunctionsStore()
const core = useCoreStore()
const finalOpen = computed({
  get: () => props.open,
  set: (newVal) => emit('update:open', newVal)
})

const hasGithubAuth = computed(() => !!core.activeUser?.hasGithubAuth)

enum Steps {
  Template,
  Details,
  Done
}

const steps = ref<BulletStepType[]>([
  { name: 'Choose a template' },
  { name: "What's your function about?" },
  { name: 'Done' }
])

const activeStep = ref<Steps>(0)
const selectedTemplate = ref<FunctionTemplateItem>()
const details = ref<DetailsFormValues>()
const newFunction = ref<CreateFunctionResponse>()
const isDetailsLoading = ref(false)

const maxWidth = computed(() => (activeStep.value === Steps.Template ? 'lg' : 'md'))

const onDetailsNext = async (vals: DetailsFormValues) => {
  const templateId = selectedTemplate.value?.language
  if (!templateId) return

  details.value = { ...vals }

  isDetailsLoading.value = true
  const res = await functions
    .createFunction({
      template: templateId,
      functionName: vals.name,
      description: vals.description,
      supportedSourceApps: (vals.allowedSourceApps || []).map((a) => a.name),
      tags: vals.tags || [],
      logo: vals.image || null,
      org: vals.org || null
    })
    .finally(() => (isDetailsLoading.value = false))

  newFunction.value = res
  activeStep.value = Steps.Done
}

const onFullyClosed = () => {
  activeStep.value = 0
  selectedTemplate.value = undefined
  details.value = undefined
  newFunction.value = undefined
}
</script>
